function isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 46 || charCode > 31 && (charCode < 48 || charCode > 57)){
        evt.preventDefault();
        return false;
    }
    return true;
}

//input numbers prodotto + fn attiva fascia di prezzo
let inputNumbers = document.querySelectorAll('.c-input-number');
inputNumbers.forEach(function (el, i) {
    let plus = el.querySelector('.plus');
    let minus = el.querySelector('.minus');
    let inputNum = el.querySelector('input');
    var event = new Event('change');
    plus.addEventListener('click', (e) => {
        inputNum.value = Number(inputNum.value) + 1;
        inputNum.dispatchEvent(event);

    });
    minus.addEventListener('click', (e) => {
        if (Number(inputNum.value) > 1) {
            inputNum.value = Number(inputNum.value) - 1;
            inputNum.dispatchEvent(event);
        }
    });

    inputNum.addEventListener("keypress", isNumberKey, false);
});


