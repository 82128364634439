import Iodine from '@caneara/iodine';
import { gsap } from 'gsap/all';
import axios from "axios"

const instance = new Iodine();
let cf = document.querySelector('#js--contact-form')
// let nf = document.querySelector('#js--newsletter-form')
let resp = document.querySelector('#js--contact-form-response');
let respTxt = document.querySelector('#js--contact-form-response-text');
// let n_resp = document.querySelector('#js--newsletter-form-response');
// let n_respTxt = document.querySelector('#js--newsletter-form-response-text');
// let ef = document.querySelector('#js--event-form')
// let e_resp = document.querySelector('#js--event-form-response');
// let e_respTxt = document.querySelector('#js--event-form-response-text');

if (cf) {
    const rules = {
        name: ['required', 'string', 'minLength:3', 'maxLength:255'],
        // surname : ['required', 'string', 'minLength:3', 'maxLength:255'],
        email: ['required', 'string', 'minLength:6', 'maxLength:150', 'email'],
        message: ['required', 'string', 'minLength:3'],
        privacy: ['required'],
    };
    let valid = false;

    cf.addEventListener('submit', function (event) {
        event.preventDefault();
        let btn = cf.querySelector('button[type="submit"]');
        btn.setAttribute("disabled", true);
        valid = true;

        for (const field in rules) {
            if (field == 'privacy') {
                let input = cf.querySelector('#' + field);
                let message = cf.querySelector('#privacy-error');
                message.classList.add('d-none');
                message.classList.remove('d-block');
                if (input.checked !== true) {
                    valid = false;
                    input.parentElement.classList.add('error');
                    message.classList.remove('d-none');
                    message.classList.add('d-block');
                }
            } else {
                let input = cf.querySelector('#' + field);
                let message = cf.querySelector('#' + field + '+span.message');
                message.classList.add('d-none');
                let result = instance.assert(input.value, rules[field]);
                if (result.valid !== true) {
                    valid = false;
                    message.classList.remove('d-none');
                }
            }

        }

        if (valid) {
            let newsletter = 0;
            // if(cf.querySelector('input[name="newsletter"]')){
            //     newsletter = cf.querySelector('input[name="newsletter"]').checked;
            // }
            // if(cf.querySelector('input[name="catalogue"]')){
            //     catalogue = cf.querySelector('input[name="catalogue"]').checked;
            // }
            axios.post(cf.action, {
                name: cf.querySelector('input[name="name"]').value,
                // surname: cf.querySelector('input[name="surname"]').value,
                //city: cf.querySelector('input[name="city"]').value,
                //business_name: cf.querySelector('input[name="business_name"]').value,
                // phone: cf.querySelector('input[name="phone"]').value,
                email: cf.querySelector('input[name="email"]').value,
                message: cf.querySelector('textarea[name="message"]').value,
                // newsletter: newsletter,
                // catalogue: catalogue
            })
                .then((response) => {
                    respTxt.innerHTML = response.data.message;
                    cf.querySelector('input[name="name"]').value = '';
                    // cf.querySelector('input[name="surname"]').value = '';
                    // cf.querySelector('input[name="phone"]').value = '';
                    cf.querySelector('input[name="email"]').value = '';
                    cf.querySelector('textarea[name="message"]').value = '';
                    let tl = gsap.timeline();
                    tl.to(resp, .001, { display: 'block' })
                        .to(resp, .5, { autoAlpha: 1 })
                        .to(resp, .5, { autoAlpha: 0 }, '+=5s')
                        .to(resp, .001, { display: 'none' });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        btn.removeAttribute('disabled');
    });
}
/*
if (nf) {
    const rules = {
        // name : ['required', 'string', 'minimum:3', 'maximum:255'],
        // surname : ['required', 'string', 'minimum:3', 'maximum:255'],
        email1: ['required', 'string', 'minLength:6', 'maxLength:150', 'email'],
        privacy1: ['required'],
    };
    let valid = false;

    // const executeNf = () => {
    //     // uses moduleA
    // }

    nf.addEventListener('submit', function (event) {
        event.preventDefault();
        // import('@caneara/iodine.Iodine')
        // .then(module => module.default) // using the default export
        // .then(() => executeNf())
        // .catch);
        valid = true;
        for (const field in rules) {
            if (field == 'privacy1') {
                let input = nf.querySelector('#'+field);
                if (input.checked !== true) {
                    valid = false;
                    input.parentElement.classList.add('error');
                }
            } else {
                let input   = nf.querySelector('#'+field);
                let message = nf.querySelector('#'+field+'+span.message');
                message.classList.add('d-none');
                let result = instance.assert(input.value, rules[field]);
                if (result.valid !== true) {
                    valid = false;
                    message.classList.remove('d-none');
                }
            }

        }
        if (valid) {
            axios.post(nf.action, {
                email: nf.querySelector('input#email1').value,
            })
            .then((response) => {
                n_respTxt.innerHTML = response.data.message;
                nf.querySelector('input#email1').value = '';
                let tl = gsap.timeline();
                tl.to(n_resp,.001,{display:'block'})
                .to(n_resp,.5,{autoAlpha:1})
                .to(n_resp,.5,{autoAlpha:0},'+=5s')
                .to(n_resp,.001,{display:'none'});
            })
            .catch((error) => {
                console.log(error);
            });
        }
    });
}

if (ef) {
    const rules = {
        name : ['required', 'string', 'minLength:3', 'maxLength:255'],
        surname : ['required', 'string', 'minLength:3', 'maxLength:255'],
        email: ['required', 'string', 'minLength:6', 'maxLength:150', 'email'],
        company : ['required', 'string', 'minLength:3', 'maxLength:255'],
        persons : ['required', 'string', 'minLength:1', 'maxLength:255'],
        // phone : ['string', 'minLength:3', 'maxLength:255'],
        privacy: ['required'],
        event_id: ['required'],
    };
    let valid = false;

    ef.addEventListener('submit', function (event) {
        event.preventDefault();
        valid = true;

        for (const field in rules) {
            if (field == 'privacy') {
                let input = ef.querySelector('#'+field);
                if (input.checked !== true) {
                    valid = false;
                    input.parentElement.classList.add('error');
                }
            }else if (field == 'event_id') {
                let input = ef.querySelector('#'+field);
                let result = instance.assert(input.value, rules[field]);
                if (result.valid !== true) {
                    valid = false;
                }
            } else {
                let input   = ef.querySelector('#'+field);
                let message = ef.querySelector('#'+field+'+span.message');
                message.classList.add('d-none');
                let result = instance.assert(input.value, rules[field]);
                if (result.valid !== true) {
                    valid = false;
                    message.classList.remove('d-none');
                }
            }

        }

        if (valid) {
            // let newsletter = 0;
            // if(cf.querySelector('input[name="newsletter"]')){
            //     newsletter = cf.querySelector('input[name="newsletter"]').checked;
            // }
            // if(cf.querySelector('input[name="catalogue"]')){
            //     catalogue = cf.querySelector('input[name="catalogue"]').checked;
            // }
            axios.post(ef.action, {
                name: ef.querySelector('input[name="name"]').value,
                surname: ef.querySelector('input[name="surname"]').value,
                company: ef.querySelector('input[name="company"]').value,
                phone: ef.querySelector('input[name="phone"]').value,
                email: ef.querySelector('input[name="email"]').value,
                persons: ef.querySelector('input[name="persons"]').value,
                lang: ef.querySelector('input[name="lang"]').value,
                event_id: ef.querySelector('input[name="event_id"]').value
            })
            .then((response) => {
                e_respTxt.innerHTML = response.data.message;
                ef.querySelector('input[name="name"]').value = '';
                ef.querySelector('input[name="surname"]').value = '';
                ef.querySelector('input[name="phone"]').value = '';
                ef.querySelector('input[name="email"]').value = '';
                ef.querySelector('input[name="company"]').value = '';
                ef.querySelector('input[name="persons"]').value = '';
                let tl = gsap.timeline();
                tl.to(e_resp,.001,{display:'block'})
                .to(e_resp,.5,{autoAlpha:1})
                .to(e_resp,.5,{autoAlpha:0},'+=5s')
                .to(e_resp,.001,{display:'none'});
            })
            .catch((error) => {
                console.log(error);
            });
        }
    });
}*/
