// import './bootstrap';

import { gsap, ScrollTrigger } from 'gsap/all';

import axios from 'axios';
import { Collapse } from 'bootstrap';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(TextPlugin);
// gsap.registerPlugin(SplitText);

import './front-utils'
import './inputNumber';
import Lottie from 'lottie-web'
import Splide from '@splidejs/splide';

let mode = (window.innerWidth < 993) ? 'mobile' : 'desktop';

// let inputBox = document.querySelector('.input-box'),
// searchIcon = document.querySelector('.search'),
// closeIcon = document.querySelector('.close-icon');

// searchIcon.addEventListener('click', () => {
//     inputBox.classList.add('open');
// });
// closeIcon.addEventListener('click', () => {
//     inputBox.classList.remove('open');
// });



let headerMobile = document.querySelector('.fixed-header-mobile');
let menuMobile = document.querySelector('#js--menu-mobile');
let logoMobile = headerMobile.querySelector('.logo-mobile');
let menuMobileContent = menuMobile.querySelector('.menu-mobile--content');
let menuMobileLinks = menuMobile.querySelectorAll('.link');
let hamburger_mobile = document.querySelector('#js--hamburger-mobile');

let mobileMenuTl = gsap.timeline({
    paused: true,
    reversed: true
})
    .to('html', { overflow: 'hidden', duration: .0007 })
    .to(menuMobile, { display: 'block', duration: .0007 })
    .to(headerMobile, { backgroundColor: 'transparent', duration: .0007 })
    .from(menuMobileContent, .05, { autoAlpha: 0 }, { autoAlpha: 1 }, 'start')
    .fromTo(hamburger_mobile, { backgroundColor: '#92694f' }, { backgroundColor: '#D0D0D0' }, 'start')
    .fromTo(menuMobileLinks, .02, { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.1 }, '-=.8s');


const JSON_hambuger_animation_mobile = Lottie.loadAnimation({
    container: hamburger_mobile, // Required
    path: hamburger_mobile.dataset.hamburger, // Required
    renderer: 'svg', // Required
    loop: false, // Optional
    autoplay: false, // Optional
    filterSize: {
        width: '100%',
        height: '100%'
    }
});
JSON_hambuger_animation_mobile.setSpeed(3);
hamburger_mobile.addEventListener('click', function () {
    if (mobileMenuTl.reversed()) {
        JSON_hambuger_animation_mobile.setDirection(1);
        JSON_hambuger_animation_mobile.play();
        setTimeout(() => {
            mobileMenuTl.play();
        }, 500);
    } else {
        mobileMenuTl.reverse().eventCallback('onReverseComplete', function () {
            JSON_hambuger_animation_mobile.setDirection(-1);
            JSON_hambuger_animation_mobile.play();
        });
    };
})

const hp_slide = document.getElementById("slider__hp");
if (hp_slide) {
    new Splide(hp_slide, {
        type: 'fade',
        pagination: false,
        arrows: false,
        autoplay: true,
        interval: 6000,
        rewind: true,
    }).mount();
}

const blog_slide = document.getElementById("related-blog");
if (blog_slide) {
    new Splide('#related-blog', {
        type: 'loop',
        perPage: 3,
        pagination: false,
        autoplay: false,
        breakpoints: {
            768: {
                perPage: 1,
            },
        },
    }).mount();
}

const about_slide = document.getElementById("related-about");
if (about_slide) {
    new Splide('#related-about', {
        type: 'loop',
        perPage: 4,
        pagination: false,
        autoplay: false,
        breakpoints: {
            768: {
                perPage: 1,
            },
        },
    }).mount();
}

// HORIZONTAL GALLERY

let images_sx = document.querySelector(".wrapper-sx");
if (mode == 'mobile') {
    gsap.set(images_sx, {
        xPercent: -25
    });
} else {
    gsap.set(images_sx, {
        xPercent: -15
    });
}
gsap.from(images_sx, {
    xPercent: -8,
    ease: "none",
    scrollTrigger: {
        trigger: images_sx,
        scrub: 1,
        start: "top bottom",
        end: "bottom"
    },
});

let images_dx = document.querySelector(".wrapper-dx");
gsap.set(images_dx, { xPercent: -18 });

gsap.to(images_dx, {
    xPercent: 7,
    ease: "none",
    scrollTrigger: {
        trigger: images_dx,
        scrub: 1,
        start: "top bottom",
        end: "bottom -=1500"
    },
});

// Images parallax
gsap.utils.toArray('.img-parallax-container').forEach(container => {
    const img = container.querySelector('img');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: container,
            scrub: true,
            pin: false,
        }
    });

    tl.fromTo(img, {
        yPercent: -15,
        ease: 'none'
    }, {
        yPercent: 15,
        ease: 'none'
    });
});

//AUTH PAGE
let formsLinks = document.querySelectorAll('[data-form-activate]');
if (formsLinks) {
    formsLinks.forEach((el, i) => {
        el.addEventListener('click', () => {
            let index = el.dataset.formActivate;
            let forms = document.querySelectorAll('[data-form]');
            let form = document.querySelector('[data-form="' + index + '"]');
            if (form) {
                forms.forEach((f, i) => {
                    let tl = gsap.timeline();
                    tl.set(f, { position: 'absolute' })
                    tl.to(f, .5, { translateX: '100%', autoAlpha: 0 })
                });
                formsLinks.forEach((f, i) => {
                    f.classList.remove('active');
                });
                setTimeout(() => {
                    formsLinks[(i)].classList.add('active');
                    let tl = gsap.timeline();
                    tl.set(form, { position: 'relative' })
                    tl.to(form, .5, { translateX: '0%', autoAlpha: 1 })
                }, 500);
            }
        });
    })
}

window.setCartIcon = (value) => {
    let lc = document.querySelectorAll('.js--lottie-cart');
    lc.forEach((l, i) => {
        let counter = l.querySelector('.js--counter');
        counter.style.display = 'none';
        counter.innerHTML = value;
        counter.style.display = 'inline';
    });
};