//IMPORT
import { gsap, ScrollTrigger, Expo } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);
import Lottie from 'lottie-web';

//EXAMPLE
// data-anim-trigger='["NOME_ANIMAZIONE", DURATION, DEELAY]'
// data-anim-trigger='["NOME_ANIMAZIONE"]'
// data-anim-scroll='["NOME_ANIMAZIONE", DURATION, DEELAY]'
// data-anim-scroll='["NOME_ANIMAZIONE"]'

// <div data-anim-trigger='["moveUp",0.75,0.2]' class="">
//     <div class="un-solo-contenitore-figlio-obbligatorio">
//
//     </div>
// </div>

// let controller = new ScrollMagic.Controller();
let dataAnimationsTrigger = document.querySelectorAll('*[data-anim-trigger]');
let dataAnimationsScroll = document.querySelectorAll('*[data-anim-scroll]');
let dataAnimations = document.querySelectorAll('*[data-anim-scroll],*[data-anim-trigger]');
let mode = (window.innerWidth < 993) ? 'mobile' : 'desktop';

//GESTIONE DESKTOP/MOBILE
let mediaQuery = window.matchMedia('(max-width: 992px)');
mediaQuery.addListener(
    function (e) {
        if (e.matches) {
            mode = 'mobile';
        } else {
            mode = 'desktop';
        }
        generateAnimations(mode);
    }
);

// setTimeout(function () {
//     generateAnimations(mode);
// }, 100);
window.addEventListener('DOMContentLoaded', (event) => {
    generateAnimations(mode);
});

function generateAnimations(mode) {
    dataAnimations.forEach((el, i) => {
        let dataType = el.dataset[Object.keys(el.dataset)[0]];
        if (el.childElementCount == 1) {
            let element = el.firstElementChild;
            var delay = 0.35;
            var duration = .5;
            let animationType;
            let animationArray;
            let scene;
            try {
                animationArray = JSON.parse(dataType);
                animationType = animationArray[0];

                //duration
                if (animationArray[1]) {
                    duration = animationArray[1];
                }
                //deelay
                if (animationArray[2]) {
                    delay = animationArray[2];
                }
            } catch (e) {
                console.log(e)
            }

            let tl = gsap.timeline({
                paused: Object.keys(el.dataset)[0] == 'animTrigger' ? true : false,
            });
            tl.defaultEase = Expo.easeInOut;

            if (el.timeline) {
                el.timeline.kill();
            }
            switch (animationType) {
                case 'fadeIn':
                    tl.fromTo(element, {
                        autoAlpha: 0
                    }, {
                        duration: duration,
                        delay: delay,
                        autoAlpha: 1,
                    });
                    break;

                case 'fadeInUp':
                    tl.fromTo(element, {
                        autoAlpha: 0,
                        yPercent: 10
                    }, {
                        duration: duration,
                        delay: delay,
                        autoAlpha: 1,
                        yPercent: 0,
                        stagger: 0.5,
                        ease: "power4.out"
                    });
                    break;

                case 'zoomIn':
                    tl.fromTo(element, {
                        scale: 1
                    }, {
                        duration: duration,
                        delay: delay,
                        stagger: 0.5,
                        scale: 1.1,
                        ease: "power4.out"
                    });
                    break;

                case 'zoomInBg':
                    if (mode == 'desktop') {
                        tl.fromTo(el, {
                            backgroundSize: "100% 100%"
                        }, {
                            duration: duration,
                            delay: delay,
                            backgroundSize: "+=3% +=3%",
                            ease: "power4.out"
                        });
                    }
                    break;
            }
            el.timeline = tl;

            setTimeout(function () {
                if (el.hasAttribute("data-anim-trigger")) {
                    //CREO SCENA
                    el.scrolltrigger = ScrollTrigger.create({
                        //markers: true,
                        trigger: el,
                        endTrigger: "html",
                        start: "top 90%",
                        end: "bottom bottom",
                        scrub: 1,
                        onToggle: (self) => {
                            if (self.isActive) {
                                el.timeline.play();
                            }
                        }
                    });
                }
                else if (el.hasAttribute("data-anim-scroll")) {
                    el.scrolltrigger = ScrollTrigger.create({
                        //markers: true,
                        trigger: el,
                        animation: el.timeline,
                        // pin:true,
                        start: "top 90%",
                        end: "bottom bottom",
                        scrub: 1
                    });
                }
            }, 10);

        } else {
            console.log('due elementi figli, raggruppali in uno');
        }
    });
};
